import React from 'react'
// import SubHeader from '../SubHeader/Index'
import Header from '../Header/Index'
import PrivacyPolicy from './_part/Index'
import Footer from '../Footer/Index'

function Index() {
  return (
    <div>
      {/* <SubHeader/> */}
      {/* <div className="line"></div> */}
      <Header/>
      <div className="line"></div>
      <PrivacyPolicy id="privacy-policy"/>
      <div className="line"></div>
      <Footer/>
    </div>
  )
}

export default Index