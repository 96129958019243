import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { ReleasedApp } from '../Utils/ReleasedApp';
function Index() {


  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 0,
    centerMode: true,
    cssEase: "linear",
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2,
                infinite: true
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                centerMode: false,
            }
        }
    ]
};
  return (
    <div className='releasedApp' id='releasedApp'>
        <div className="title" data-aos="fade-up"
     data-aos-anchor-placement="center-bottom" data-aos-duration="1500">
          <h1>Top Trending Apps</h1>
          <p>Centum Solutions creates the helpful mobile application solutions to make you connected with the world of mobility. </p>
        </div>
       <Slider {...settings}>
      {
        ReleasedApp.map(({id,image,button,title,rating})=>{
          return(
            <div>
            <div className="app-card" key={id}>
              <img src={image} alt="Translator" />
              <div className="app-content" data-aos="zoom-in" data-aos-duration="1500">
              <a href=""><span>{button}</span></a>
              <h3>{title}</h3>
              <div className='rating'>
                <i className="fa fa-star"></i>
                <p>{rating}</p>
              </div>
              </div>
            </div>
          </div>
          )
        })
      }
        </Slider>
      </div>
  )
}

export default Index