import React from "react";
import Vpn from "../../assets/images/Vpn.png";
import BirthReminder from "../../assets/images/BirthReminder.png";
import CartoonFact from "../../assets/images/CartoonFact.png";
import LangTrans from "../../assets/images/LangTrans.png";
import PlayStore from "../../assets/images/PlayStore.png";
import Slider from "react-slick";

function Index() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
      data-aos="fade-left" data-aos-duration="1500"
        className={className}
        style={{ ...style, display: "block", background: "#151a28" ,border:"1px solid #1077fb" }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
      data-aos="fade-right" data-aos-duration="1500"
        className={className}
        style={{ ...style, display: "block", background: "#151A28",border:"1px solid #1077fb" }}
        onClick={onClick}
      />
    );
  }

  return (
    <div className="app-slider" id="appRecomended">
      <div className="container">
      <div className="row">
      <div className="col-lg-5" data-aos="fade-right" data-aos-duration="1500">
              <h3>Apps Recommend For You This Week</h3>
            </div>
      </div>
        <Slider {...settings}>
          <div className="container px-md-0 px-0">
            <div className="row">
              <div className="col-lg-6 col-md-6 ">
                <div className="d-lg-flex d-block  justify-content-between">
                  <div className="over" data-aos="fade-right" data-aos-duration="1500">
                  <img src={Vpn} alt="Vpn" />
                  </div>
                  <div className="recomended-content" data-aos="fade-left" data-aos-duration="1500">
                    <h2>Fast VPN - Virtual Private Network<span></span></h2>
                    <h6>Tools</h6>
                    <p>
                    This is a game about the battle of the
                    strongest humans on earth
                    </p>
                    <div className="playStore">
                     <a href=""> <img src={PlayStore} alt="PlayStore" /></a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="d-lg-flex d-block justify-content-between">
                  <div className="over" data-aos="fade-right" data-aos-duration="1500">
                  <img src={BirthReminder} alt="BirthReminder" />
                  </div>
                  <div className="recomended-content" data-aos="fade-left" data-aos-duration="1500">
                    <h2>Birthday Reminder - All Events<span></span></h2>
                    <h6>Productivity</h6>
                    <p>
                    Adventure in a dangerous snake cave with
                    friends who were going on vacation
                    </p>
                    <div className="playStore">
                     <a href=""> <img src={PlayStore} alt="PlayStore" /></a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="d-lg-flex d-block justify-content-between">
                  <div className="over" data-aos="fade-right" data-aos-duration="1500">
                  <img src={CartoonFact} alt="CartoonFact" />
                  </div>
                  <div className="recomended-content" data-aos="fade-left" data-aos-duration="1500">
                    <h2>Cartoon Factory - For Kids<span></span></h2>
                    <h6>Entertainment</h6>
                    <p>
                    A superhero wearing a spider mask who
                    helps many people in the world
                    </p>
                    <div className="playStore">
                     <a href=""> <img src={PlayStore} alt="PlayStore" /></a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="d-lg-flex d-block justify-content-between">
                  <div className="over" data-aos="fade-right" data-aos-duration="1500">
                  <img src={LangTrans} alt="LangTrans" />
                  </div>
                  <div className="recomended-content" data-aos="fade-left" data-aos-duration="1500">
                    <h2>Language Translator -STT & TTS<span></span></h2>
                    <h6>Communication</h6>
                    <p>
                    Omongken is the best podcast that you is
                    the best podcast that you can use
                    </p>
                    <div className="playStore">
                     <a href=""> <img src={PlayStore} alt="PlayStore" /></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container px-md-0 px-0">
            <div className="row">
              <div className="col-lg-6 col-md-6 ">
                <div className="d-lg-flex d-block  justify-content-between">
                  <div className="over" data-aos="fade-right" data-aos-duration="1500">
                  <img src={Vpn} alt="Vpn" />
                  </div>
                  <div className="recomended-content" data-aos="fade-left" data-aos-duration="1500">
                    <h2>Fast VPN - Virtual Private Network<span></span></h2>
                    <h6>Tools</h6>
                    <p>
                    This is a game about the battle of the
                    strongest humans on earth
                    </p>
                    <div className="playStore">
                     <a href=""> <img src={PlayStore} alt="PlayStore" /></a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="d-lg-flex d-block justify-content-between">
                  <div className="over" data-aos="fade-right" data-aos-duration="1500">
                  <img src={BirthReminder} alt="BirthReminder" />
                  </div>
                  <div className="recomended-content" data-aos="fade-left" data-aos-duration="1500">
                    <h2>Birthday Reminder - All Events<span></span></h2>
                    <h6>Productivity</h6>
                    <p>
                    Adventure in a dangerous snake cave with
                    friends who were going on vacation
                    </p>
                    <div className="playStore">
                     <a href=""> <img src={PlayStore} alt="PlayStore" /></a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="d-lg-flex d-block justify-content-between">
                  <div className="over" data-aos="fade-right" data-aos-duration="1500">
                  <img src={CartoonFact} alt="CartoonFact" />
                  </div>
                  <div className="recomended-content" data-aos="fade-left" data-aos-duration="1500">
                    <h2>Cartoon Factory - For Kids<span></span></h2>
                    <h6>Entertainment</h6>
                    <p>
                    A superhero wearing a spider mask who
                    helps many people in the world
                    </p>
                    <div className="playStore">
                     <a href=""> <img src={PlayStore} alt="PlayStore" /></a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="d-lg-flex d-block justify-content-between">
                  <div className="over" data-aos="fade-right" data-aos-duration="1500">
                  <img src={LangTrans} alt="LangTrans" />
                  </div>
                  <div className="recomended-content" data-aos="fade-left" data-aos-duration="1500">
                    <h2>Language Translator -STT & TTS<span></span></h2>
                    <h6>Communication</h6>
                    <p>
                    Omongken is the best podcast that you is
                    the best podcast that you can use
                    </p>
                    <div className="playStore">
                     <a href=""> <img src={PlayStore} alt="PlayStore" /></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container px-md-0 px-0">
            <div className="row">
              <div className="col-lg-6 col-md-6 ">
                <div className="d-lg-flex d-block  justify-content-between">
                  <div className="over" data-aos="fade-right" data-aos-duration="1500">
                  <img src={Vpn} alt="Vpn" />
                  </div>
                  <div className="recomended-content" data-aos="fade-left" data-aos-duration="1500">
                    <h2>Fast VPN - Virtual Private Network<span></span></h2>
                    <h6>Tools</h6>
                    <p>
                    This is a game about the battle of the
                    strongest humans on earth
                    </p>
                    <div className="playStore">
                     <a href=""> <img src={PlayStore} alt="PlayStore" /></a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="d-lg-flex d-block justify-content-between">
                  <div className="over" data-aos="fade-right" data-aos-duration="1500">
                  <img src={BirthReminder} alt="BirthReminder" />
                  </div>
                  <div className="recomended-content" data-aos="fade-left" data-aos-duration="1500">
                    <h2>Birthday Reminder - All Events<span></span></h2>
                    <h6>Productivity</h6>
                    <p>
                    Adventure in a dangerous snake cave with
                    friends who were going on vacation
                    </p>
                    <div className="playStore">
                     <a href=""> <img src={PlayStore} alt="PlayStore" /></a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="d-lg-flex d-block justify-content-between">
                  <div className="over" data-aos="fade-right" data-aos-duration="1500">
                  <img src={CartoonFact} alt="CartoonFact" />
                  </div>
                  <div className="recomended-content" data-aos="fade-left" data-aos-duration="1500">
                    <h2>Cartoon Factory - For Kids<span></span></h2>
                    <h6>Entertainment</h6>
                    <p>
                    A superhero wearing a spider mask who
                    helps many people in the world
                    </p>
                    <div className="playStore">
                     <a href=""> <img src={PlayStore} alt="PlayStore" /></a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="d-lg-flex d-block justify-content-between">
                  <div className="over" data-aos="fade-right" data-aos-duration="1500">
                  <img src={LangTrans} alt="LangTrans" />
                  </div>
                  <div className="recomended-content" data-aos="fade-left" data-aos-duration="1500">
                    <h2>Language Translator -STT & TTS<span></span></h2>
                    <h6>Communication</h6>
                    <p>
                    Omongken is the best podcast that you is
                    the best podcast that you can use
                    </p>
                    <div className="playStore">
                     <a href=""> <img src={PlayStore} alt="PlayStore" /></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


        </Slider>
      </div>
    </div>
  );
}

export default Index;
