import React,{useEffect} from 'react';
import './App.css';
import Index from "./components/Index"
import PrivacyPolicy from "./components/PrivacyPolicy/Index"
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';
function App() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    // <PrivacyPolicy />
   <Router>
     <Routes>
          <Route path="/" exact element={<Index />} />
          <Route path="/privacy-policy" exact element={<PrivacyPolicy />} />
         
        </Routes>
   </Router>
  );
}

export default App;
