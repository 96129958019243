import React from 'react'

function Index() {
  return (
    <div className="signup">
        <div className="container">
          <div className="row mx-0">
            <div className="col-md-6 col-12 mx-auto">
              <div data-aos="flip-up" data-aos-duration="1500">
              <h2>Sign Up For Our Newsletter</h2>
              </div>
              <div class="input-group mb-3" data-aos="flip-down" data-aos-duration="1500">
                <input type="text" class="form-control" placeholder="Enter your e-mail here" aria-label="Enter your e-mail here" aria-describedby="basic-addon2"/>
                <span class="input-group-text" id="basic-addon2"><span>Subscribe Now</span></span>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

export default Index