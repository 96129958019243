import React, { useState, useCallback } from "react";
import Logo from "../../assets/images/logo.png";
// import facebook from "../../assets/images/facebook.svg";
// import instagram from "../../assets/images/instagram.svg";
// import twitter from "../../assets/images/twitter.svg";
import { Photos } from "./Photos";
// import { render } from "react-dom";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
function Index() {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };
  return (
    <div>
      <div className="footer">
        <div className="container">
          <div className="row mx-0">
            <div className="col-lg-4 col-md-6 col-12">
             <div data-aos="fade-right" data-aos-duration="1500">
             <img src={Logo}  alt="" />
             </div>
              <div className="links" data-aos="fade-left" data-aos-duration="1500">
                <p>
                Centum Solutions creates the helpful mobile application solutions to make you connected with the world of mobility.
                </p>
              </div>
              <div className="social">
              <div class="social_media_div" id="social">
            <ul class="icons_list">
                <li data-aos="zoom-in" data-aos-duration="1500">
                    <a href="https://www.facebook.com/"><i class="facebook-icon fab fa-facebook-f"></i></a>
                </li>
                <li data-aos="zoom-in" data-aos-duration="1500">
                    <a href="https://instagram.com/"><i class="instagram-icon fab fa-instagram"></i></a>
                </li>
                <li data-aos="zoom-in" data-aos-duration="1500">
                    <a href="http://twitter.com/"><i class="twitter-icon fab fa-twitter"></i></a>
                </li>
                <li data-aos="zoom-in" data-aos-duration="1500">
                    <a href="https://play.google.com/store/search?q=centumsolutions&c=apps" target="_blank"><i class="playStore-icon fab fa-google-play"></i></a>
                </li>
            </ul>
        </div>
              </div>
            </div>
            {/* <div className="col-md-2 col-6">
              <h2 className="footer-link">Useful Links</h2>
              <ul className="links">
                <li><span>About Us</span></li>
                <li><span>Game</span></li>
                <li><span>Match</span></li>
                <li><span>Store</span></li>
                <li><span>Blog</span></li>
              </ul>
            </div>
            <div className="col-md-3 col-6">
              <h2 className="footer-link">Social Contact</h2>
              <ul className="links">
                <li><span>Facebook</span></li>
                <li><span>Twitter</span></li>
                <li><span>Instagram</span></li>
                <li><span>Youtube</span></li>
                <li><span>Github</span></li>
              </ul>
            </div> */}
            <div className="col-lg-4 order-first order-md-0 mb-md-0 mb-5">
              <h2>Gallery</h2>
                  <ul className="gallary" data-aos="zoom-out" data-aos-duration="1500">
              <Gallery photos={Photos} onClick={openLightbox}  />
                <ModalGateway>
                  {viewerIsOpen ? (
                    <Modal onClose={closeLightbox}>
                      <Carousel
                        currentIndex={currentImage}
                        views={Photos.map(x => ({
                          ...x,
                          srcset: x.srcSet,
                          caption: x.title
                        }))}
                        />
                    </Modal>
                  ) : null}
                </ModalGateway>
                  </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="copywrite">
       <div className="container">
       <p className="mb-0">
          © Copyright 2022. All Rights Reserved by Centum Solutions
        </p>
       </div>
      </div>
    </div>
  );
}

export default Index;
