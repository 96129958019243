import React from "react";
import Modal from "react-bootstrap/Modal";
import CentumVideo from "../../assets/images/CentumVideo.m4v";
function Index(props) {
  return (
    <React.Fragment>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
         <Modal.Header closeButton>
      </Modal.Header>
       <div className="bimatchVideo">
       <video autoPlay width="100%" loop controls>
        <source src={CentumVideo} type="video/mp4"/>
        <source src={CentumVideo} type="video/ogg"/>
        </video>
       </div>
      </Modal>
    </React.Fragment>
  );
}

export default Index;
