
export const ReleasedApp=[
  {
    "id": 0,
    "image": require("../../assets/images/translator.png"),
    "button": "App",
    "title": "Language Translator - STT & TTS",
    "rating": "8.6 / 10 Action-rpg, platfrom"
  },
  {
    "id": 1,
    "image": require("../../assets/images/statusSaver.png"),
    "button": "App",
    "title": "All Status Saver - Story Saver",
    "rating": "8.6 / 10 Action-rpg, platfrom"
  },
  {
    "id": 2,
    "image": require("../../assets/images/Birthday.png"),
    "button": "App",
    "title": "Birthday Reminder - All Events",
    "rating": "8.6 / 10 Action-rpg, platfrom"
  },
  {
    "id": 3,
    "image": require("../../assets/images/Cartoon.png"),
    "button": "App",
    "title": "Cartoon Factory - For Kids",
    "rating": "8.6 / 10 Action-rpg, platfrom"
  },
  {
    "id": 4,
    "image": require("../../assets/images/translator.png"),
    "button": "App",
    "title": "Language Translator - STT & TTS",
    "rating": "8.6 / 10 Action-rpg, platfrom"
  },
  {
    "id": 5,
    "image": require("../../assets/images/statusSaver.png"),
    "button": "App",
    "title": "All Status Saver - Story Saver",
    "rating": "8.6 / 10 Action-rpg, platfrom"
  },
  {
    "id": 6,
    "image": require("../../assets/images/Birthday.png"),
    "button": "App",
    "title": "Birthday Reminder - All Events",
    "rating": "8.6 / 10 Action-rpg, platfrom"
  },
  {
    "id": 7,
    "image": require("../../assets/images/Cartoon.png"),
    "button": "App",
    "title": "Cartoon Factory - For Kids",
    "rating": "8.6 / 10 Action-rpg, platfrom"
  }
]

