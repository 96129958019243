import React from "react";

function Index() {
  return (
    <div className="privacyPolicy">
      <div className="container">
      <div className="heading mt-0">
          <h2>Privacy Policy</h2>
        </div>
        <div className="para">
          <p>
            The privacy of your personal information is afforded the highest
            level of importance by centum solutions
          </p>
          <p>
            We will strictly comply with all relevant legislative requirements,
            and, in the event of any inconsistency, the legislative requirements
            will override the provisions of this document.
          </p>
          <p>
            We reserve the right to amend our Privacy Policy at any time without
            notice to you, and such amendments will take effect from the date of
            issue on our website.
          </p>
          <p>
            If you are a minor at law (which usually means you are aged under 18
            in countries including Australia and the United States, though this
            age may vary depending on where you are located), you must get your
            parent or guardian’s permission before providing us with any
            personal information.
          </p>
        </div>
        <div className="heading">
          <h2>Collection of Information</h2>
        </div>
        <div className="para">
          <p>
            We will only collect information where it is reasonably necessary to
            do so for the conduct of our business. Any collection of personal
            information by us will be fair and lawful and will not be intrusive.
          </p>
          <p>
            We may collect information regarding your use of our software,
            applications and/or services, including information about the
            duration of your use, the features you use and your interactions
            with other users of the software, applications and/or services. We
            may collect information regarding the device you use to access the
            software, applications and/or services, including (where applicable)
            its model, operating system, screen resolution and other technical
            information.
          </p>
        </div>
        <div className="heading">
          <h2>Use and Disclosure of Information</h2>
        </div>
        <div className="para">
          <p>
            In general, we will not use or disclose personal information about
            you otherwise than for the purpose of providing or offering goods
            and services to you, for any other purpose that you may reasonably
            expect, for any other purpose authorized by law, or for any other
            purposes disclosed to or authorized by you. This may include
            disclosures to organizations that provide us with professional
            advice, such as solicitors, accountants and business advisers.
          </p>
          <p>
            It may be necessary for the information to be disclosed to other
            organizations in the course of our provision of services, or for the
            conduct of our business. These other organizations may include other
            parties involved in the provision of services to you, our business
            partners involved in the software, applications and/or services we
            provide to you, and other organizations providing services to us,
            such as payment processors or hosting services. We direct all such
            organizations to maintain the confidentiality of the information
            disclosed to them and to not use your information for any purpose
            other than to provide services on our behalf or to us.
          </p>
          <p>
            We may provide aggregated or anonymous information to third parties
            for the purposes of developing and delivering targeted advertising.
            We will not share your personal information with such third parties
            without your consent.
          </p>
          <p>
            We may use information held about you to verify your compliance with
            any terms and conditions that may apply to use of our software,
            applications and/or services.
          </p>
          <p>
            The precise information required to be provided will vary depending
            on the circumstances requiring disclosure of that information. Any
            personal information submitted to us online, including via the
            software and/or services, may need to be processed by a third party.
            By submitting personal information online or via the software and/or
            services, you consent to the disclosure of that information to a
            third party, who may be located overseas, for the sole purpose of
            processing the submitted information.
          </p>
        </div>
        <div className="heading">
          <h2>Transfer of Information Overseas</h2>
        </div>
        <div className="para">
            <p>You expressly agree that we may transfer and store your information on servers and equipment located in any territory, including, without limitation, Australia and the United States of America etc.</p>
            <p>What we do with the information we gather?
            We require this information to understand your needs and provide you with a better service, and in particular for the following reasons:
            </p>
            <ul>
                <li>Internal record keeping.</li>
                <li>We may use the information to improve our products and services.</li>
            </ul>
        </div>
        <div className="heading">
          <h2>Security</h2>
        </div>
        <div className="para">
            <p>We are committed to ensuring that your information is secure. In order to prevent unauthorized access or disclosure we have put in place suitable physical, electronic and managerial procedures to safeguard and secure the information we collect online.</p>
            <p>For better user experiences we may get the following information</p>
            <ul>
                <li>Demographic information such as location, preferences and interests for re-marketing purposes.</li>
                <li>Your public information if social media is used in the app.</li>
            </ul>
        </div>
        <div className="heading">
          <h2>Children</h2>
        </div>
        <div className="para">
            <p>We encourage parents to guide their children about privacy and the way they use their information on the Internet. Children should never disclose their name, address or phone number, or any personal information, without their parents’ former permission. We consider a user to be a child if they are under age of 13, unless more stringent regulation applies in their country of residence. In EU countries, users under the age of 16 are considered children, unless the data protection regulation of their country of residence specifies another age. If we consider that any of our Services are directed at users under the minimum legal age required in their country of residence, we can block such users from using the Services or from providing information and/or limit the collection of information from such users as more particularly described below. No personal information should be submitted to centum solutions by users under the minimum legal age required in their country of residence. We apply the following limitations to personal information collection for a user that is not of the minimum legal age required in their country of residence. Such a user cannot: if he/she display real name or any information in the apps, access the chat, leaderboard, forums, SNS (Facebook, Game Center, etc.), subscribe to newsletters, and use any other feature where users could appear under their real names. With respect to such a user, we will prevent the collection and use of his/her precise geolocation data and display to him/her contextual advertising only (excluding any behavioral advertising). Children can be invited to take part in testing our apps (playtests) with their parents' authorization and/or their parents' physical presence being required depending on the country where the playtest is conducted. Parents must exercise their privacy rights for their children. Please refer to the "Your rights" section of this Privacy Policy.</p>
        </div>
        <div className="heading">
            <h2>Links to other websites</h2>
        </div>
        <div className="para">
            <p>Our game may contain advertising and links to other websites of interest. However, once you have used these links to leave our site, you should note that we do not have any control over that other website. Therefore, we cannot be responsible for the protection and privacy of any information which you provide whilst visiting such sites and such sites are not governed by this privacy statement. You should exercise caution and look at the privacy statement applicable to the website in question.</p>
        </div>
        <div className="heading">
            <h2>Controlling your personal information</h2>
        </div>
        <div className="para">
            <p>We will not sell, distribute or lease your personal information to third parties, except in delivery of this game, unless we have your permission or are required by law to do so. We may use your personal information to send you promotional information about third parties which we think you may find interesting.</p>
        </div>
        <div className="heading">
            <h2>How to contact us</h2>
        </div>
        <div className="para">
            <p>If you have any comments, questions or concerns regarding our Privacy Policy and/or practices, please contact us:</p>
            <p>Email: <a href="mailto:centumsolutions007@gmail.com">centumsolutions007@gmail.com</a></p>
        </div>
      </div>
    </div>
  );
}

export default Index;
