import React from 'react'
import '../../assets/css/style.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from '../Header/Index'
import Play from '../../assets/images/play.svg'
import { useEffect, useState } from 'react';
function Index() {
  const [offset, setOffset] = useState(0);

    useEffect(() => {
        const onScroll = () => setOffset(window.pageYOffset);
        // clean up code
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });
        return () => window.removeEventListener('scroll', onScroll);
    }, []);

    if(offset){
      if (offset > 300) {
        document.getElementById('social').style.display = "none"
      }
      else{
        document.getElementById('social').style.display = "block"
      }
    }
  return (
    <React.Fragment>
    <div className='homeSection' id='homeSection'>
        <Header/>
        <div className="innerSection">
        <div className="container">
        <div className="row">
          <div className="col-md-7" data-aos="flip-up" data-aos-duration="1500">
            <div className="homeContent">
              <h1>We make solutions, you make memories</h1>
              <p>As Centum Solutions Our focus is on users need. We create simple, smooth solutions that add value in your life. We target real-world problems based on users experience with better design and technology.</p>
              <div className="learnPlay">
                <div className="learnBtn">
                  <button className='btn-12'><span>Click!</span><span>Learn More</span></button>
                </div>
                <div className="playBtn">
                  <div className="playIcon">
                    <img src={Play} alt="play" />
                  </div>
                    <div className="playtext">
                      <p>PLAY</p>
                      <p>SHOWREEL</p>
                    </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-5">

          </div>
        </div>
        <div class="social_media_div" id="social">
            <ul class="icons_list">
                <li data-aos="fade-left"  data-aos-duration="500">
                    <a href="https://www.facebook.com/"><i class="facebook-icon fab fa-facebook-f"></i></a>
                </li>
                <li data-aos="fade-left" data-aos-duration="1000">
                    <a href="https://instagram.com/"><i class="instagram-icon fab fa-instagram"></i></a>
                </li>
                <li data-aos="fade-left" data-aos-duration="1500">
                    <a href="http://twitter.com/"><i class="twitter-icon fab fa-twitter"></i></a>
                </li>
            </ul>
        </div>
        </div>
        </div>
    </div>
    </React.Fragment>
  )
}

export default Index