import React from 'react'
import Home from './Home/Index'
import ReleasedApp from './ReleasedApp/Index'
import BigMatch from './BigMatch/Index'
import AppRecomended from './AppRecomended/Index'
import NewsLetter from './NewsLetter/Index'
import Footer from './Footer/Index'

function Index() {
  return (
    <div>
      <Home/>
      <ReleasedApp/>
      <BigMatch/>
      <AppRecomended/>
      <NewsLetter/>
      <Footer/>
    </div>
  )
}

export default Index