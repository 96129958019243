export const Photos = [
  {
    src: require("../../assets/images/BirthReminder.png"),
    width: 3,
    height: 4,
  },
  {
    src: require("../../assets/images/CartoonFact.png"),
    width: 3,
    height: 4,
  },
  {
    src: require("../../assets/images/LangTrans.png"),
    width: 4,
    height: 3,
  },
  {
    src: require("../../assets/images/Vpn.png"),
    width: 3,
    height: 4,
  },
  {
    src: require("../../assets/images/Ftranslator.png"),
    width: 4,
    height: 3,
  },
  {
    src: require("../../assets/images/statusSaverc.png"),
    width: 1,
    height: 1,
  },
];
