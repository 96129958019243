import React,{useState,useEffect} from 'react'
import '../../assets/css/style.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Logo from '../../assets/images/logo.png'
import {Link} from "react-router-dom"
import Scrollspy from 'react-scrollspy'
import AnchorLink from "react-anchor-link-smooth-scroll";
// import SubHeader from '../SubHeader/Index'
function Index() {
  const[color, setColor]=useState("#homeSection");
  const[page, setpage]=useState("");

  const [sticky, setSticky] = useState("");

  // on render, set listener
  useEffect(() => {
    const location = window.location.href.split('/');
    console.log(location[3]);
    
    setpage(location[3]);
    if (page !== '') {
      // alert('not empty')
      setColor('#newslatter')
      // console.log("======================> Color",color);
    } else{
      setColor('#homeSection')
    }
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, [page]);

 

  const isSticky = () => {
    /* Method that will fix header after a specific scrollable */
    const scrollTop = window.scrollY;
    const stickyClass = scrollTop >= 180 ? "is-sticky" : "";
    setSticky(stickyClass);
    console.log(stickyClass);
  };

  const classes = `header-section ${sticky}`;

  const [scrolled, setScrolled] = React.useState(false);
  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 360) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  });
  let navbarClasses = ["navbar"];
  if (scrolled) {
    navbarClasses.push("scrolled");
  }

  return (
    <header className={classes}>
    <Navbar expand="lg" >
    <div className='container'>
      <Navbar.Brand href="/" ><img src={Logo} alt="logo" /></Navbar.Brand>
      <Navbar.Toggle aria-controls="navbarScroll" />
      <Navbar.Collapse id="navbarScroll" navbarScroll>
      <Scrollspy
              offset="10"
              items={[
                "homeSection",
                "releasedApp",
                "bigMatch",
                "appRecomended",
              ]}
              currentClassName="is-current"
            >
        <Nav
          className="mx-xl-auto me-lg-auto my-lg-0 custom-margin"
        >
         {page==="privacy-policy" ? <ul>
          <li><a
                href="/" style={{borderBottom:color==="#homeSection"?"3px solid #01bae3":null}} onClick={()=>setColor("#homeSection")}>Home</a></li>
          <li><a
                href="/" style={{borderBottom:color==="#releasedApp"?"3px solid #01bae3":null}} onClick={()=>setColor("#releasedApp")}>Trending Apps</a></li>
          <li><a style={{borderBottom:color==="#bigMatch"?"3px solid #01bae3":null}} onClick={()=>setColor("#bigMatch")}
                href="/">Upcoming</a></li>
          <li><a style={{borderBottom:color==="#appRecomended"?"3px solid #01bae3":null}} onClick={()=>setColor("#appRecomended")}
                href="/">Recommended Apps</a></li>
          <li><Link to="/privacy-policy" style={{borderBottom:color==="#newslatter"?"3px solid #01bae3":null}} onClick={()=>{
            setColor("#newslatter");
      
          }}>Privacy Policy</Link></li>
         </ul> : <ul>
          <li><AnchorLink offset="115"
                href="#homeSection" style={{borderBottom:color==="#homeSection"?"3px solid #01bae3":null}} onClick={()=>setColor("#homeSection")}>Home</AnchorLink></li>
          <li><AnchorLink  offset="115"
                href="#releasedApp" style={{borderBottom:color==="#releasedApp"?"3px solid #01bae3":null}} onClick={()=>setColor("#releasedApp")}>Trending Apps</AnchorLink></li>
          <li><AnchorLink  offset="115" style={{borderBottom:color==="#bigMatch"?"3px solid #01bae3":null}} onClick={()=>setColor("#bigMatch")}
                href="#bigMatch">Upcoming</AnchorLink></li>
          <li><AnchorLink  offset="115" style={{borderBottom:color==="#appRecomended"?"3px solid #01bae3":null}} onClick={()=>setColor("#appRecomended")}
                href="#appRecomended">Recommended Apps</AnchorLink></li>
          <li><Link to="/privacy-policy" style={{borderBottom:color==="#newslatter"?"3px solid #01bae3":null}} onClick={()=>{
            setColor("#newslatter");
      
          }}>Privacy Policy</Link></li>
         </ul>}
        </Nav>
          </Scrollspy>
        <div class="search-box">
        <input class="search-txt" type="text" name="" placeholder="Type to Search"/>
          <a class="search-btn" href='#homeSection'>
            <i class="fa fa-search" aria-hidden="true"></i>
          </a>
        </div>
      </Navbar.Collapse>
    </div>
  </Navbar>
  </header>
  )
}

export default Index