import React,{useState} from 'react'
// import FreeFire from '../../assets/images/free-fire.svg'
import { useReactCountdown } from 'use-react-countdown';
import VideoModal from '../VideoModal/Index'
import playBtn from '../../assets/images/playBtn.svg'

function Index() {
    const { days, hours, minutes, seconds } = useReactCountdown(
        'July 16, 2022 00:00:00'
      );
      const [modalShow, setModalShow] = useState(false);
  return (
       <React.Fragment>
        <div className='bigMatch' id='bigMatch'>
        <div className="title" data-aos="fade-right" data-aos-duration="1500">
            <h1>Coming Soon</h1>
            {/* <img src={FreeFire} alt="FreeFire" /> */}
            <h2>To Do Reminder</h2>
            <p>July 15, 2022 4:00 PM</p>
        </div>
        <div className="timer" data-aos="fade-left" data-aos-duration="1500">
            <div className="timeBox">
            <div className="time">
            {days} 
            </div>
            <h2>Days</h2>
            </div>
            <div className="timeBox">
                <div className="time">
                {hours}
                </div>
            <h2>Hours</h2>
            </div>
            <div className="timeBox">
                <div className="time">
                {minutes}
                </div>
            <h2>Minutes</h2>
            </div>
            <div className="timeBox">
                <div className="time">
                {seconds}
                </div>
            <h2>Seconds</h2>
            </div>
        </div>
        <div className="watchVideo" data-aos="flip-up" data-aos-duration="1500">
        <button className='modal-btn' onClick={() => setModalShow(true)}><span>Watch Now <img src={playBtn} alt="playBtn" /></span></button>
        <VideoModal show={modalShow}
        onHide={() => setModalShow(false)}/>
        </div>
    </div>
       </React.Fragment>
  )
}

export default Index